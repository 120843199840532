.terms-section {
    padding: 20px;
    background-color: #f8f9fa;
    color: #333;
  }
  
  .terms-container {
    max-width: 800px;
    margin: 0 auto;
  }
  
  .terms-section h2 {
    text-align: center;
    font-size: 2rem;
    margin-bottom: 20px;
  }
  
  .terms-content {
    line-height: 1.6;
  }
  
  .terms-content h3 {
    font-size: 1.5rem;
    margin-top: 20px;
    color: #0056b3;
  }
  
  .terms-content p {
    font-size: 1rem;
    margin-bottom: 15px;
  }
  
  .terms-content a {
    color: #007bff;
    text-decoration: none;
  }
  
  .terms-content a:hover {
    text-decoration: underline;
  }
  
  @media (max-width: 768px) {
    .terms-container {
      padding: 15px;
    }
  
    .terms-section h2 {
      font-size: 1.5rem;
    }
  
    .terms-content h3 {
      font-size: 1.2rem;
    }
  }
  