.about-us {
    background-color: #f4f4f4;
    padding: 50px 20px;
    font-family: 'Arial', sans-serif;
  }
  
  .about-us-container {
    max-width: 900px;
    margin: 0 auto;
    background-color: #fff;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .about-us h2 {
    text-align: center;
    font-size: 32px;
    color: #333;
    margin-bottom: 20px;
  }
  
  .about-us-description {
    font-size: 18px;
    color: #555;
    line-height: 1.6;
    margin-bottom: 20px;
  }
  
  .about-us h3 {
    font-size: 24px;
    color: #333;
    margin-top: 30px;
    margin-bottom: 10px;
  }
  
  .about-us-values {
    list-style-type: none;
    padding: 0;
  }
  
  .about-us-values li {
    font-size: 18px;
    color: #555;
    margin-bottom: 10px;
    padding-left: 20px;
    position: relative;
  }
  
  .about-us-values li::before {
    content: '✔';
    position: absolute;
    left: 0;
    color: #4CAF50;
  }
  
  @media (max-width: 768px) {
    .about-us-container {
      padding: 20px;
    }
  
    .about-us h2 {
      font-size: 28px;
    }
  
    .about-us-description {
      font-size: 16px;
    }
  
    .about-us h3 {
      font-size: 22px;
    }
  
    .about-us-values li {
      font-size: 16px;
    }
  }
  