.features {
  padding-top: 2rem;
  text-align: center;
}

.features-list {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
  justify-content: center;
}

.feature-item {
  text-align: center;
  padding: 25px;
  background-color: #fef8e6;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 30%;
}

.feature-item img {
  max-width: 100px;
}

.list {
  display: flex;
  flex-direction: column;
  gap: 15px;
  justify-content: center;
  margin-top: 2rem;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.list__item {
  display: flex;
  align-items: center;
  background-color: #f57327;
  padding: 10px;
  border-radius: 8px;
  justify-content: flex-start;
  height: auto;
}

.list__item-img {
  margin-right: 15px;
}

.list__item-text {
  color: white;
  font-size: 16px;
  text-align: left;
}

@media (max-width: 768px) {
  /* Features Section - Full screen for mobile with padding */
  .features {
    padding-left: 10px;  /* Add left padding */
    padding-right: 10px;  /* Add right padding */
  }

  .features-list {
    flex-direction: column;  /* Stack feature items vertically */
  }

  .feature-item {
    width: 100%;  /* Full width for each feature item */
    margin-bottom: 15px;  /* Add spacing between items */
    padding: 20px;  /* Adjust padding for mobile */
  }

  .list {
    flex-direction: column;
    gap: 10px;  /* Reduced gap for mobile */
    width: 100%;
    margin-top: 2rem;
    margin-left: auto;
    margin-right: auto; /* Ensure content is centered */
  }

  .list__item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 60px;  /* Reduced height for mobile */
    padding: 8px;  /* Reduced padding for mobile */
  }

  .list__item-img {
    margin-right: 10px;  /* Space between icon and text */
  }

  .list__item-text {
    color: white;
    font-size: 14px;  /* Adjust font size for smaller screens */
    text-align: left;
  }

  /* To add right margin for mobile and center content */
  .list__item {
    width: 100%;
    padding: 10px;
    margin-right: 10px; /* Add right margin to shift the content slightly to the left */
  }
}
