/* Reset */
body {
    margin: 0;
    font-family: Arial, sans-serif;
    box-sizing: border-box;
  }
  
  /* Gallery Section */
  .photo-gallery {
    padding: 20px;
    background-color: #f9f9f9;
  }
  
  .gallery-title {
    text-align: center;
    font-size: 24px;
    margin-bottom: 20px;
    color: #333;
  }
  
  /* Gallery Grid */
  .gallery-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    gap: 15px;
    padding: 0 10px;
  }
  
  /* Individual Gallery Item */
  .gallery-item {
    overflow: hidden;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    background-color: #fff;
  }
  
  .gallery-item img {
    width: 100%;
    height: auto;
    transition: transform 0.3s ease, opacity 0.3s ease;
  }
  
  .gallery-item img:hover {
    transform: scale(1.05);
    opacity: 0.9;
  }
  