/* Overall container for info */
.info-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    font-family: 'Arial', sans-serif;
  }
  
  /* Card for product (women's and men's product) */
  .product-card {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 100%;
  }
  
  /* Title of product */
  .product-title {
    font-size: 24px;
    font-weight: bold;
    color: #333;
    margin-bottom: 20px;
  }
  
  /* Product description */
  .product-description {
    font-size: 16px;
    color: #555;
    margin-bottom: 20px;
    line-height: 1.6;
  }
  
  /* Product image */
  .product-image {
    max-width: 200px;
    height: auto;
    margin-bottom: 20px;
    border-radius: 8px;
    object-fit: cover;
  }
  
  /* Product note */
  .product-note {
    font-size: 14px;
    color: #888;
    margin-top: 10px;
    font-style: italic;
  }
  
  /* Additional information section */
  .additional-info {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    margin-top: 30px;
    margin-bottom: 40px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
  }
  
  .additional-info h3 {
    font-size: 20px;
    color: #333;
    margin-bottom: 15px;
  }
  
  .additional-info p,
  .additional-info ul {
    font-size: 16px;
    color: #555;
    line-height: 1.6;
    margin-bottom: 20px;
  }
  
  .additional-info ul {
    padding-left: 20px;
  }
  
  .additional-info ul li {
    margin-bottom: 10px;
  }
  
  /* Money-back guarantee note */
  .money-back-note {
    background-color: #ffe6e6;
    padding: 15px;
    border-radius: 8px;
    border: 1px solid #ff6666;
    color: #b30000;
    font-size: 16px;
    margin-top: 30px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
  }
  
  .money-back-note h3 {
    font-size: 18px;
    color: #b30000;
    margin-bottom: 10px;
  }
  
  .money-back-note p {
    font-size: 16px;
    color: #b30000;
    line-height: 1.6;
  }
  
  /* Responsive Design for Mobile */
  @media (max-width: 768px) {
    .product-card {
      padding: 15px;
    }
  
    .product-title {
      font-size: 20px;
    }
  
    .product-description {
      font-size: 14px;
    }
  
    .product-image {
      max-width: 150px;
    }
  
    .additional-info h3 {
      font-size: 18px;
    }
  
    .additional-info p,
    .additional-info ul {
      font-size: 14px;
    }
  
    .money-back-note {
      padding: 12px;
    }
  
    .money-back-note h3 {
      font-size: 16px;
    }
  
    .money-back-note p {
      font-size: 14px;
    }
  }
  
  /* Responsive Design for Small Devices */
  @media (max-width: 480px) {
    .product-title {
      font-size: 18px;
    }
  
    .product-description {
      font-size: 13px;
    }
  
    .product-image {
      max-width: 120px;
    }
  
    .additional-info h3 {
      font-size: 16px;
    }
  
    .additional-info p,
    .additional-info ul {
      font-size: 12px;
    }
  
    .money-back-note h3 {
      font-size: 14px;
    }
  
    .money-back-note p {
      font-size: 12px;
    }
  }
  