.privacy-policy {
    background-color: #f9f9f9;
    padding: 50px 20px;
    font-family: 'Arial', sans-serif;
  }
  
  .privacy-policy-container {
    max-width: 900px;
    margin: 0 auto;
    background-color: #fff;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .privacy-policy h2 {
    text-align: center;
    font-size: 32px;
    color: #333;
    margin-bottom: 20px;
  }
  
  .privacy-policy p {
    font-size: 16px;
    color: #555;
    line-height: 1.6;
  }
  
  .policy-section {
    margin-bottom: 30px;
  }
  
  .policy-section h3 {
    font-size: 24px;
    color: #333;
    margin-bottom: 10px;
  }
  
  .policy-section p {
    font-size: 16px;
    color: #555;
  }
  
  @media (max-width: 768px) {
    .privacy-policy-container {
      padding: 20px;
    }
  
    .privacy-policy h2 {
      font-size: 28px;
    }
  
    .policy-section h3 {
      font-size: 22px;
    }
  
    .policy-section p {
      font-size: 14px;
    }
  }
  