.contact-us {
    background-color: #f9f9f9;
    padding: 50px 20px;
    font-family: 'Arial', sans-serif;
  }
  
  .contact-us-container {
    max-width: 900px;
    margin: 0 auto;
    background-color: #fff;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .contact-us h2 {
    text-align: center;
    font-size: 32px;
    color: #333;
    margin-bottom: 20px;
  }
  
  .contact-description {
    text-align: center;
    font-size: 18px;
    color: #555;
    margin-bottom: 40px;
  }
  
  .contact-info {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
  }
  
  .contact-item {
    width: 45%;
  }
  
  .contact-item h3 {
    font-size: 24px;
    color: #333;
    margin-bottom: 10px;
  }
  
  .contact-item p {
    font-size: 18px;
    color: #555;
  }
  
  .contact-item a {
    color: #007BFF;
    text-decoration: none;
  }
  
  .contact-item a:hover {
    text-decoration: underline;
  }
  
  .contact-form h3 {
    font-size: 24px;
    color: #333;
    margin-bottom: 20px;
  }
  
  .contact-form label {
    font-size: 16px;
    color: #333;
    margin-bottom: 8px;
    display: block;
  }
  
  .contact-form input,
  .contact-form textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
  }
  
  .contact-form button {
    padding: 12px 20px;
    background-color: #007BFF;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
  }
  
  .contact-form button:hover {
    background-color: #0056b3;
  }
  
  @media (max-width: 768px) {
    .contact-info {
      flex-direction: column;
      align-items: center;
    }
  
    .contact-item {
      width: 100%;
      text-align: center;
      margin-bottom: 20px;
    }
  
    .contact-form {
      margin-top: 30px;
    }
  }
  