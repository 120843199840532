.male-female-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    padding: 20px;
    background-color: #f4f4f4;
  }
  
  .card {
    background: #ffffff;
    border: 1px solid #ddd;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    max-width: 300px;
    text-align: center;
  }
  
  .product-image {
    width: 110px;
    height: 200px;
    margin: 0 auto;
    display: block;
  }
  
  .card-title {
    font-size: 1.2rem;
    font-weight: bold;
    color: #333;
    margin: 15px 0;
  }
  
  .card-list {
    text-align: left;
    margin: 15px 0;
    padding-left: 20px;
    font-size: 0.9rem;
    color: #555;
  }
  
  .card-description {
    margin-top: 10px;
    font-size: 0.9rem;
    color: #666;
    line-height: 1.5;
  }
  
  @media (max-width: 768px) {
    .male-female-container {
      flex-direction: column;
      align-items: center;
    }
  
    .card {
      max-width: 90%;
    }
  }
  