.doctor-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    background-color: #f9f9f9;
  }
  
  .doctor-card {
    max-width: 400px;
    text-align: center;
    background: #ffffff;
    border: 1px solid #ddd;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
  }
  
  .doctor-image {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin: 0 auto;
    display: block;
  }
  
  .doctor-name {
    font-size: 1.2rem;
    color: #333333;
    margin: 10px 0;
    font-weight: bold;
  }
  
  .doctor-description {
    font-size: 0.9rem;
    line-height: 1.5;
    color: #555555;
    margin: 10px 0;
  }
  
  .certification {
    margin-top: 15px;
  }
  
  .certification-stamp {
    width: 80px;
    height: auto;
    display: block;
    margin: 0 auto;
  }
  