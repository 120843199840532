.warning-container {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffcc00;
    padding: 20px;
    border-radius: 10px;
    border: 2px solid #e6a800;
    margin: 20px 0;
    font-family: Arial, sans-serif;
    color: #333;
    font-size: 16px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .warning-icon {
    font-size: 40px;
    margin-right: 20px;
  }
  
  .warning-message {
    font-size: 16px;
    max-width: 600px;
  }
  
  .warning-message h2 {
    font-size: 20px;
    font-weight: bold;
    color: #c70000;
  }
  
  .warning-message p {
    margin: 10px 0;
  }
  
  .warning-message strong {
    color: #c70000;
  }
  