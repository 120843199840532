/* General Styles */
.main-container {
  font-family: Arial, sans-serif;
  width: 90%;
  margin: auto;
}

.hero-section {
  padding: 20px 0;
  margin-top: 20px;
  background-color: #fef8e6;
  border-radius: 10px;
}

.hero-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.hero-video {
  position: relative;
  max-width: 500px;
  margin-bottom: 20px;
}

.video-element {
  width: 100%;
  border-radius: 10px;
}

.unmute-button {
  position: absolute;
  bottom: 20px;
  right: 20px;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  font-size: 16px;
  padding: 8px 12px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  opacity: 0.8;
  transition: opacity 0.3s ease, transform 0.2s ease;
}

.unmute-button:hover {
  opacity: 1;
  transform: scale(1.05);
}

.hero-content {
  max-width: 600px;
  text-align: center;
}
.hero-content h1 {
  font-size: 24px;
  color: #f57327;
  margin-bottom: 15px; /* Add space between heading and first paragraph */
}

.hero-content p {
  margin: 10px 0; /* Space between paragraphs */
  font-size: 16px;
  color: #555;
  line-height: 1.5;
}

.hero-content .btn-order {
  display: inline-block;
  padding: 10px 20px;
  background-color: #f57327;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  margin-top: 20px; /* Add space between the last paragraph and the button */
}


.btn-order {
  background-color: #f57327;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.btn-order:hover {
  background-color: #d45e1b;
}
