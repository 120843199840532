/* General Styling */
body {
  font-family: Arial, sans-serif;
  background-color: #f4f4f4;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Blog List */
.blog-section {
  padding: 20px;
  background-color: #fff;
}

.blog-container {
  width: 80%;
  margin: 0 auto;
}

h2 {
  text-align: center;
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 30px;
}

/* Blog Post List */
.blog-posts {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.blog-post {
  width: 100%;
  max-width: 380px;
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid #ddd;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease;
}

.blog-post:hover {
  transform: scale(1.05);
}

.blog-post-image {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-bottom: 2px solid #f1f1f1;
}

.blog-post-content {
  padding: 15px;
}

.blog-post-content h3 {
  font-size: 1.8rem;
  color: #333;
  margin-bottom: 15px;
}

.blog-post-date {
  font-size: 0.9rem;
  color: #888;
  margin-bottom: 10px;
}

.blog-post-content p {
  font-size: 1rem;
  color: #555;
  margin-bottom: 10px;
}

.read-more-link {
  display: inline-block;
  margin-top: 15px;
  color: #007bff;
  text-decoration: none;
  font-weight: bold;
}

.read-more-link:hover {
  text-decoration: underline;
}

/* Full Blog Post */
.blog-details-section {
  padding: 40px 20px;
  background-color: #fff;
}

.blog-details-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.blog-details-container h2 {
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 20px;
}

.blog-details-container .blog-post-date {
  color: #888;
  font-size: 1rem;
  margin-bottom: 20px;
}

.blog-details-container img {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 8px;
  margin-bottom: 20px;
}

.blog-details-container p {
  font-size: 1.1rem;
  line-height: 1.8;
  color: #555;
  margin-bottom: 20px;
}

/* Responsive Styling */
@media (max-width: 768px) {
  .blog-posts {
    flex-direction: column;
    align-items: center;
  }

  .blog-post {
    max-width: 100%;
  }
}
