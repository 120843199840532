.product-detail {
    padding: 20px;
    background-color: #f9f9f9;
    font-family: Arial, sans-serif;
  }
  
  .text-center {
    text-align: center;
  }
  
  .product-card {
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    width: 320px; /* Adjusted for larger image size */
    margin: 0 auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .discount-tag {
    background-color: #f57327;
    color: #fff;
    font-weight: bold;
    padding: 5px 10px;
    border-radius: 5px;
    display: inline-block;
    margin-bottom: 10px;
  }
  
  .product-images img {
    width: 200px; /* Increased width for larger image */
    height: auto; /* Maintain aspect ratio */
    margin: 10px 0;
  }
  
  .product-info {
    font-size: 16px;
    color: #333;
    margin: 10px 0;
  }
  
  .price-section {
    margin: 20px 0;
  }
  
  .current-price {
    font-size: 24px;
    font-weight: bold;
    color: #f57327;
    margin-right: 10px;
  }
  
  .original-price {
    text-decoration: line-through;
    color: #999;
  }
  
  .tax-info {
    font-size: 14px;
    color: #555;
  }
  
  .shop-now-btn {
    display: inline-block;
    padding: 10px 20px;
    background-color: #f57327;
    color: #fff;
    text-decoration: none;
    border-radius: 5px;
    font-weight: bold;
    transition: background-color 0.3s;
  }
  
  .shop-now-btn:hover {
    background-color: #ff4c41;
  }
  