.form-section {
  padding: 30px;
  background-color: #fff7eb;
  border: 1px solid #f5c6a5;
  border-radius: 10px;
  max-width: 500px;
  margin: 20px auto;
  text-align: center;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.form-heading {
  font-size: 24px;
  color: #d35400;
  font-weight: bold;
  margin-bottom: 15px;
}

.offer-points {
  text-align: left;
  color: #555;
  margin-bottom: 20px;
  line-height: 1.8;
}

.offer-highlight {
  font-size: 22px;
  color: #e74c3c;
  font-weight: bold;
  margin: 10px 0;
}

.guarantee-text {
  color: #2c3e50;
  margin: 5px 0;
}

.limited-offer {
  color: #16a085;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 20px;
}

form {
  margin-top: 15px;
}

label {
  display: block;
  margin: 10px 0 5px;
  text-align: left;
  font-weight: bold;
  color: #444;
}

.order-input {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
}

.submit-button {
  width: 100%;
  padding: 12px;
  background-color: #e67e22;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s;
}

.submit-button:hover {
  background-color: #d35400;
}

.info-button {
  width: 50%;
  padding: 6px;
  background-color: #e67e22;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 12px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s;
}

.submit-button:hover {
  background-color: #d35400;
}

.timer {
  font-size: 16px;
  color: #ff0000;
  font-weight: bold;
  margin-top: 15px;
  text-align: center;
}
