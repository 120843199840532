/* Header Styles */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 30px;
  background: linear-gradient(to right, #f8dfc4, #f6c5a8); /* Light beige-to-peach gradient */
  color: #6b2f1d;
  font-family: 'Arial', sans-serif;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 10;
}

.logo {
  font-size: 24px;
  font-weight: bold;
  color: #6b2f1d;
}

.nav-links {
  display: flex;
  gap: 20px;
}

.nav-links a {
  color: #6b2f1d;
  text-decoration: none;
  font-weight: 500;
}

.nav-links a:hover {
  color: #b04b2a;
  text-decoration: underline;
}

.menu-toggle {
  display: none; /* Hidden on desktop */
  font-size: 30px;
  color: #6b2f1d;
  cursor: pointer;
}

/* Mobile View */
@media screen and (max-width: 768px) {
  .menu-toggle {
    display: block; /* Show hamburger icon on mobile */
  }

  .nav-links {
    display: none; /* Hide menu initially */
  }

  .nav-links.open {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    background: #f8dfc4; /* Light beige tone */
    padding: 10px 0;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
    z-index: 100;
  }

  .nav-links a {
    padding: 10px 0;
  }
}
