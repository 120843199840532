body {
    background-color: #f6f7fb;
    font-family: sans-serif;
    color: #6b7c93;
  }
  
  .container {
    width: 80%;
    margin: 0 auto;
    margin-top: 4em;
  }
  
  .order-block {
    padding: 2em;
    background-color: #ffffff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    text-align: center;
  }
  
  .text-holder {
    margin-top: 2em;
    margin-bottom: 2em;
  }
  
  h1 {
    font-size: 2em;
    margin-bottom: 0.5em;
    color: #3a3f4b;
  }
  
  h2 {
    font-size: 1.5em;
    margin-bottom: 0.5em;
    color: #3a3f4b;
  }
  
  p {
    font-size: 1.2em;
    line-height: 1.5;
    margin-bottom: 2em;
  }
  
  .btn {
    display: inline-block;
    padding: 1em 2em;
    background-color: #ff7f66;
    color: #ffffff;
    border-radius: 5px;
    text-decoration: none;
    transition: all 0.2s ease;
    cursor: pointer;
  }
  
  .btn:hover {
    background-color: #ff5a36;
  }
  