.testimonial-section {
    background-color: #f9f9f9;
    padding: 40px 20px;
  }
  
  .testimonial-heading {
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    color: #333;
    margin-bottom: 30px;
  }
  
  .testimonial-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  }
  
  .testimonial-card {
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    max-width: 300px;
    text-align: center;
    position: relative;
  }
  
  .client-quote {
    font-size: 16px;
    font-style: italic;
    color: #555;
    margin-bottom: 15px;
  }
  
  .client-info-area {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
  }
  
  .client-info-wrap {
    display: flex;
    align-items: center;
  }
  
  .client-img img {
    border-radius: 50%;
    width: 50px;
    height: 50px;
    object-fit: cover;
  }
  
  .client-info h3 {
    font-size: 16px;
    color: #333;
    margin-left: 10px;
  }
  
  .quote-icon {
    font-size: 24px;
    color: #e67e22;
    position: absolute;
    bottom: 10px;
    right: 10px;
  }
  