.success-couples-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    background-color: #e3f2fd; /* Theme color */
  }
  
  .success-card {
    background: #ffffff;
    border: 1px solid #ddd;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    max-width: 600px;
    text-align: center;
  }
  
  .bottle-image {
    width: 150px;
    height: auto;
    margin: 0 auto 20px;
  }
  
  .success-title {
    font-size: 1.5rem;
    font-weight: bold;
    color: #0077c2; /* Theme color */
    margin-bottom: 20px;
  }
  
  .success-paragraph {
    font-size: 1rem;
    color: #555;
    line-height: 1.6;
    margin-bottom: 15px;
  }
  
  @media (max-width: 768px) {
    .success-card {
      max-width: 90%;
    }
  
    .bottle-image {
      width: 120px;
    }
  
    .success-title {
      font-size: 1.3rem;
    }
  
    .success-paragraph {
      font-size: 0.9rem;
    }
  }
  