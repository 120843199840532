/* Static Call Now Button - Reduced Size */
html, body {
  height: 100%;
  margin: 0;
  overflow-x: hidden; /* Hide horizontal scrolling */
  overflow-y: auto;   /* Allow vertical scrolling */
}

.call-now-button {
  position: fixed; /* Stays fixed on the screen */
  top: 80px; /* Distance from the top */
  right: 20px; /* Distance from the right */
  background-color: #28a745; /* Green background */
  color: white; /* White text and icon color */
  padding: 7px 10px; /* Reduced padding */
  border-radius: 20px; /* Slightly smaller rounded button */
  display: flex; /* Align icon and text horizontally */
  align-items: center; /* Center items vertically */
  justify-content: center; /* Center items horizontally */
  font-size: 14px; /* Reduced font size */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2); /* Adds shadow */
  z-index: 1000; /* Places it above all elements */
  cursor: pointer; /* Pointer cursor on hover */
  text-decoration: none; /* Removes underline */
  transform: scale(1); /* Original size */
  opacity: 0; /* Initially hidden */
  animation: slideFromTop 1s forwards 3s; /* Animation from top after 3 seconds */
}

.call-now-button:hover {
  background-color: #218838; /* Darker green on hover */
  transform: scale(1.1); /* Enlarges slightly on hover */
  transition: transform 0.2s ease, background-color 0.2s ease; /* Smooth animation */
}

.phone-icon {
  font-size: 18px; /* Reduced font size for the phone icon */
  margin-right: 7px; /* Reduced space between the icon and text */
}

.call-text {
  font-weight: bold; /* Makes the text bold */
}

/* Static Order Now Button - Larger Size */
.order-now-button {
  position: fixed; /* Stays fixed on the screen */
  bottom: 20px; /* Distance from the bottom */
  right: 20px; /* Distance from the right */
  background-color: #d35400; /* Orange background */
  color: white; /* White text */
  padding: 10px 15px; /* Padding for the button */
  border-radius: 20px; /* Rounded button */
  display: flex; /* Align icon and text horizontally */
  align-items: center; /* Center items vertically */
  justify-content: center; /* Center items horizontally */
  font-size: 16px; /* Font size for the text */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2); /* Adds shadow */
  z-index: 1000; /* Places it above all elements */
  cursor: pointer; /* Pointer cursor on hover */
  text-decoration: none; /* Removes underline */
  transform: scale(0.7); /* Increased size to 70% */
  opacity: 0; /* Initially hidden */
  animation: slideFromBottom 1s forwards 6s; /* Animation from bottom after 6 seconds */
}

.order-now-button:hover {
  background-color: #c84300; /* Darker orange on hover */
  transform: scale(1.1); /* Enlarges slightly on hover */
  transition: transform 0.2s ease, background-color 0.2s ease; /* Smooth animation */
}

.order-icon {
  font-size: 18px; /* Icon size */
  margin-right: 7px; /* Space between icon and text */
}

.order-text {
  font-weight: bold; /* Makes the text bold */
}

/* Fade-In and Slide Animations */
@keyframes slideFromTop {
  0% {
    transform: translateY(-100px) scale(1); /* Starts above the screen */
    opacity: 0; /* Initially hidden */
  }
  100% {
    transform: translateY(0) scale(1); /* Ends at its original position */
    opacity: 1; /* Fully visible */
  }
}

@keyframes slideFromBottom {
  0% {
    transform: translateY(100px) scale(0.7); /* Starts below the screen, with increased size */
    opacity: 0; /* Initially hidden */
  }
  100% {
    transform: translateY(0) scale(0.7); /* Ends at its original position and size */
    opacity: 1; /* Fully visible */
  }
}
