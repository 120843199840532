.blog-details-section {
  padding: 20px;
  font-family: Arial, sans-serif;
  background-color: #f9f9f9;
}

.blog-details-container {
  max-width: 800px;
  margin: 0 auto;
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.blog-details-title {
  font-size: 28px;
  font-weight: bold;
  color: #2c3e50;
  margin-bottom: 10px;
}

.blog-post-date {
  color: #888;
  font-size: 14px;
  margin-bottom: 20px;
}

.blog-details-image {
  width: 100%;
  height: auto;
  border-radius: 8px;
  margin-bottom: 20px;
}

.blog-details-content h2 {
  font-size: 24px;
  color: #2c3e50;
  margin-top: 20px;
}

.blog-details-content h3 {
  font-size: 20px;
  color: #34495e;
  margin-top: 15px;
}

.blog-details-content p {
  line-height: 1.8;
  color: #555;
  margin-bottom: 15px;
}

.blog-details-content ul {
  margin-left: 20px;
  list-style-type: disc;
  color: #555;
}
